/** @jsx jsx */
import { jsx, Spinner, Button, Text } from "theme-ui";
import React, { useEffect } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";
import Seo from "../components/seo";

let isReceiptPrinted = false;
let lastVisitedUrl;

const MonerisIframe = () => {
  const [isMobileSite] = usePersist("isMobileSite", "");

  const handleClose = () => {
    const url = new URL(window.location.origin + window.location.pathname);
    url.searchParams.set("status", "failure");
    window.location.href = url;
  };

  const closeCheckOut = (close) => {
    if (close && isMobileSite) {
      handleClose();
      return;
    }
    if (lastVisitedUrl) {
      navigate(lastVisitedUrl);
    } else {
      navigate("/");
    }
  };
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            moneriRedirectAckUri
            monerisPaymentGatewayLib
          }
        }
      }
    `
  );

  const { moneriRedirectAckUri, monerisPaymentGatewayLib } = site.siteMetadata;

  const [trkId] = useQueryParam("trkId", StringParam);
  const [amount] = useQueryParam("amount", StringParam);
  const [currency] = useQueryParam("currency", StringParam);

  const getParams = () => {
    const data = {
      ticket: trkId,
      amount,
      currency,
    };

    return data;
  };

  useEffect(() => {
    lastVisitedUrl = document.referrer;
    const urlParams = getParams();

    const myPageLoad = (res) => {
      const response = JSON.parse(res);
      // const monerisCheckoutLoader = document.getElementById(
      //   "monerisCheckoutLoader"
      // );
      // const monerisCheckoutHolder = document.getElementById(
      //   "monerisCheckoutHolder"
      // );
      // monerisCheckoutLoader.style.display = "none";
      // monerisCheckoutHolder.style.display = "block";
      if (
        response.response_code === "2003" ||
        response.response_code === "2002" ||
        response.response_code === "2001"
      ) {
        const sessionInvalidResponse = `<div style="text-align: center; margin-top: 10%; ">
                    <b style="font-size: 24px;">Moneris Secure Payment.</b>
                    <br />
                    <b style="font-size: 24px; ">Session invalid. Please try again.</b>
                    <br />
                    <button id="monerisCloseBtn"
                        style="margin-top:20px ;color:#7fb17f ;border:2px solid ;
                    padding:5px 20px; border-radius:5px; font-weight: bolder;"
                    >Close</button>
                </div >`;

        const content = document.getElementById("monerisCheckoutHolder");
        content.innerHTML = sessionInvalidResponse;
        document
          .getElementById("monerisCloseBtn")
          .addEventListener("click", () => {
            if (isMobileSite) {
              handleClose();
              return;
            }
            isReceiptPrinted = true;
            if (lastVisitedUrl) {
              navigate(lastVisitedUrl);
            } else {
              navigate("/");
            }
          });
      }
    };

    const myCancelTransaction = (res) => {
      const response = JSON.parse(res);
      if (response.response_code === "001") {
        document.getElementById("monerisCheckoutCancelPopup").style.display =
          "block";
      }
    };

    function createRequest(data) {
      const url = new URL(moneriRedirectAckUri);
      Object.assign(data, {
        currency: urlParams.currency,
      });
      url.search = new URLSearchParams(data).toString();

      fetch(url, {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              if (json.successUrl || json.failureUrl) {
                window.location.href = json.successUrl || json.failureUrl;
              }
            });
          }
          if (response) {
            isReceiptPrinted = true;
          }
        })
        .catch((err) => {
          return `${err} url: ${url}`;
        });
    }

    const myErrorEvent = (res) => {
      const response = JSON.parse(res);
      const param = {
        trackingId: response.ticket,
        currency: urlParams.currency,
        amount: urlParams.amount,
      };
      createRequest(param);
    };

    const myPaymentComplete = (res) => {
      if (isReceiptPrinted) {
        closeCheckOut();
        return;
      }
      const response = JSON.parse(res);
      const param = {
        trackingId: response.ticket,
        currency: urlParams.currency,
        amount: urlParams.amount,
      };
      createRequest(param);
    };

    const myPaymentReceipt = (res) => {
      const response = JSON.parse(res);
      if (response) {
        isReceiptPrinted = true;
      }
      const param = {
        trackingId: response.ticket,
        currency: urlParams.currency,
        amount: urlParams.amount,
      };
      createRequest(param);
    };

    const paymentMode = monerisPaymentGatewayLib.includes("gateway.moneris")
      ? "prod"
      : "qa";

    setTimeout(() => {
      /* eslint-disable-next-line  new-cap */
      const myCheckout = new window.monerisCheckout();
      myCheckout.setMode(paymentMode);
      myCheckout.setCheckoutDiv("monerisCheckoutHolder");
      myCheckout.startCheckout(urlParams.ticket);
      myCheckout.setCallback("page_loaded", myPageLoad);
      myCheckout.setCallback("cancel_transaction", myCancelTransaction);
      myCheckout.setCallback("error_event", myErrorEvent);
      myCheckout.setCallback("payment_complete", myPaymentComplete);
      myCheckout.setCallback("payment_receipt", myPaymentReceipt);
    }, 3000);
  });

  const handleCancelTransaction = () => {
    document.getElementById("monerisCheckoutCancelPopup").style.display =
      "none";
  };

  return (
    <React.Fragment>
      <Seo
        script={[
          {
            type: "text/javascript",
            src: monerisPaymentGatewayLib,
          },
        ]}
        title="Donation - Canada Donation"
      />
      <div
        id="monerisCheckoutLoader"
        sx={{
          height: "100vh !important",
          zIndex: "100003 !important",
          display: "block",
          background: "rgba(255,255,255)",
          textAlign: "center",
        }}
      >
        <b sx={{ fontSize: "24px" }}>Moneris Secure Payment</b>
        <p sx={{ margin: "10px 0" }}>
          <Spinner /> Loading...
        </p>
      </div>
      <div
        id="monerisCheckoutHolder"
        sx={{
          zIndex: "100003 !important",
          // display: "block",
        }}
      />
      <div
        id="monerisCheckoutCancelPopup"
        sx={{
          fontWeight: 700,
          backdropFilter: "blur(2px)",
          height: "85vh !important",
          width: "100%",
          position: "absolute",
          zIndex: "100003 !important",
          top: "12%",
          display: "none",
          padding: "25px !important",
          textAlign: "center",
        }}
      >
        <Text>
          Are you sure, you want to cancel your transaction?
          <div sx={{ mt: "3" }}>
            <Button
              sx={{
                mr: "3",
                color: "#7fb17f",
                border: "2px solid",
                padding: "2px 0px",
                borderRadius: "5px",
                fontWeight: "bolder",
                width: "20%",
              }}
              onClick={() => handleCancelTransaction()}
            >
              No
            </Button>
            <Button
              sx={{
                color: "#7fb17f",
                border: "2px solid",
                padding: "2px 0px",
                borderRadius: "5px",
                fontWeight: "bolder",
                width: "20%",
              }}
              onClick={() => closeCheckOut("close")}
            >
              Yes
            </Button>
          </div>
        </Text>
      </div>
    </React.Fragment>
  );
};

export default MonerisIframe;
